import React from "react"
import { Helmet } from 'react-helmet'

import "../styles/style.css"

import heroImage from "../../static/images/BeFunky-collage.jpg"
import covid19 from "../../static/images/whatapp-share-usman-catering.jpg"

export default () => (
  <>
    <Helmet>
      <title>Usman Catering @ 94914 89321 - Harinadhapuram, Nellore </title>
    </Helmet>
    <main className="hero">
      <div className="card">
        <h1 className="site-name">Usman Catering</h1>
        <p className="sub-heading">Craving for delicious food at an affordable with highest quality <b>Veg</b> and <b>Non-Veg</b> food items to be part of your celebration.</p>
        <a href="tel:+919491489321" className="call-button">Call <b>Shaik Rafi</b> @ 94914 89321</a>
        <p>
          <b>Address:</b>
        <address>
          Usman Catering, 6th street, Harinathpuram, Nellore
        </address>
        </p>
        <img className="hero-image" src={heroImage} alt="Usman catering food items preview"/>
      </div>
      <div className="card">
        <h2>Affordable food for Coronavirus(Covid-19) pandemic effected people in Nellore</h2>
        <img className="covid" src={covid19} alt="Covid-19 Usman Catering food for poor at Nellore"/>
        <p>The whole world is suffering with Coronavirus, many have lost thier jobs and many poor people are hungry.</p>
        <p>As an effort help the needy we are providing quality food.</p>
        <h3>At <b>₹15</b> / plate</h3>
        <ol>
          <li>White rice</li>
          <li>Sambar</li>
          <li>Chutney</li>
          <li>Fry</li>
          <li>Buttermilk</li>
          <li>Pickle</li>
        </ol>
        <p>Food is prepare with utmost hygenie while maintaining social distancing</p>
        <p>If you are someone donating food in your locality, kindly maintain socail distancing</p>
        <p>— Shaik Rafi</p>
      </div>
    </main>
  </>
)